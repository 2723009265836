// NOTE: This file is "borrowed" (and "fixed") from @cp-shared-8/packages/frontend/ui/src/components/validation-inputs/validated-textarea/ValidatedTextarea.tsx
// (there weren't enough/working properties on the original)

import React, { FocusEventHandler, FormEventHandler, Fragment, MouseEventHandler, useEffect, useState } from 'react';
import { useField } from 'formik';
import { FormField, Textarea } from '@vwfs-bronson/bronson-react';
import { InfoIcon } from '@cp-shared-8/frontend-ui';
import { ValidationErrorMessage } from '../errors';
import { HelpText } from '../help-text/HelpText';

const getValueLength = (value: string | undefined): number => value?.length ?? 0;

const Counter: React.FC<{ count: number; maximum: number; testId?: string }> = ({ count, maximum, testId }) => {
    return (
        <div className={'c-input__counter'} aria-live={'polite'} data-testid={testId ? `${testId}Counter` : undefined}>
            {`${count} / ${maximum} characters used`}
        </div>
    );
};

export type ValidatedTextareaProps = {
    name: string;
    maxLength?: number;
    showCounter?: boolean;
    id?: string;
    placeholder?: string;
    testId?: string;
    tooltip?: string;
    onLinkClick?: MouseEventHandler<HTMLTextAreaElement>;
    label: string;
    helpText?: string | React.ReactNode;
    className?: string;
    disabled?: boolean;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    rows?: number;
    cols?: number;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
    handleChange?: FormEventHandler<HTMLTextAreaElement>;
};

export const ValidatedTextarea: React.FC<ValidatedTextareaProps & React.HTMLAttributes<HTMLTextAreaElement>> = ({
    id,
    testId,
    name,
    tooltip,
    onLinkClick,
    label,
    helpText,
    className,
    maxLength,
    showCounter,
    placeholder,
    disabled,
    isMandatory = false,
    withHtmlValidationWarning = false,
    handleChange,
    cols,
    rows,
    onFocus,
}) => {
    const [field, meta] = useField({ name, type: 'textarea' });
    const { onChange: fieldOnChange, ...fieldRemaining } = field;

    const [currentCount, setCurrentCount] = useState<number>(getValueLength(field.value));

    useEffect(() => {
        showCounter && setCurrentCount(getValueLength(field.value));
    }, [showCounter, field.value]);

    const labelText = disabled ? <span className={'u-text-disabled'}>{label}</span> : label;
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const errorMessageElseHelpText = (
        <Fragment>
            <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
            <HelpText meta={meta} testId={testId}>
                {helpText}
            </HelpText>
        </Fragment>
    );

    return (
        <FormField
            className={className}
            type="textarea"
            onClick={onLinkClick}
            labelText={labelText as string | undefined}
            testId={`${testId}FormField`}
            id={id}
            errorMessage={errorMessageElseHelpText}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            staticLabel
        >
            <Textarea
                id={id}
                testId={testId}
                placeholder={placeholder}
                maxLength={maxLength}
                disabled={disabled}
                cols={cols}
                rows={rows}
                onFocus={onFocus}
                onChange={(event): void => {
                    showCounter && setCurrentCount(getValueLength(event.target.value));
                    fieldOnChange(event);
                }}
                {...fieldRemaining}
            />
            {maxLength && showCounter && <Counter count={currentCount} maximum={maxLength} testId={testId} />}
        </FormField>
    );
};
