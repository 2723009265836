export function getRequestAdditionalHelpEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-additional-help/${encryptedContractId}`;
}

export function getRequestPayInOneGoEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-pay-in-one-go/${encryptedContractId}`;
}

export function getRequestPayInInstalmentsEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-pay-in-instalments/${encryptedContractId}`;
}

export function getRequestBreathingSpaceEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-breathing-space/${encryptedContractId}`;
}

export function getRequestOtherOptionsEndpoint(encryptedContractId = ':encryptedContractId'): string {
    return `/request-other-options/${encryptedContractId}`;
}
