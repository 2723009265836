// The following URLs needs to be whitelisted within IDK (including domain and port)
export const authPaths = {
    loginCallback: (): string => '/authentication/callback',
    logoutCallback: (): string => '/authentication/logout',
    silentRenew: (): string => '/authentication/silent_callback',
};

export function notFoundPagePath(): string {
    return '/not-found';
}

export function loginErrorPagePath(): string {
    return '/login-error';
}

export function sessionTimeoutPagePath(): string {
    return '/session-timeout';
}

export function landingPagePath(): string {
    return '/';
}

export function legalNoticePagePath(): string {
    return '/legal-notice';
}

export function privacyPolicyPagePath(): string {
    return '/privacy-policy';
}

export function cookiePolicyPagePath(): string {
    return '/cookie-policy';
}

export function modernSlaveryStatementPagePath(): string {
    return '/modern-slavery-statement';
}

export function thirdPartyLicencesPagePath(): string {
    return '/third-party-licences';
}

export function registerPagePath(): string {
    return '/register';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function outstandingPaymentsPagePath(encryptedContractId = ':encryptedContractId'): string {
    return `/dashboard/outstanding-payments/${encryptedContractId}`;
}

export function requestAdditionalHelpPagePath(
    encryptedContractId = ':encryptedContractId',
    initialOption = ':initialOption?',
): string {
    return `/dashboard/request-additional-help/${encryptedContractId}${!!initialOption ? `/${initialOption}` : ''}`;
}

export function earlySettlementPagePath(encryptedContractId = ':encryptedContractId'): string {
    return `/dashboard/early-settlement/${encryptedContractId}`;
}

export function earlyTerminationPagePath(encryptedContractId = ':encryptedContractId'): string {
    return `/dashboard/early-termination/${encryptedContractId}`;
}

export function endOfContractPagePath(encryptedContractId = ':encryptedContractId'): string {
    return `/dashboard/end-of-contract/${encryptedContractId}`;
}

export function brandCarInsurancePagePath(
    insuranceBrand = ':insuranceBrand',
    encryptedContractId = ':encryptedContractId',
): string {
    return `/dashboard/${insuranceBrand}-car-insurance/${encryptedContractId}`;
}

export function startMatchmakerPagePath(encryptedContractId = ':encryptedContractId'): string {
    return `/dashboard/start-matchmaker/${encryptedContractId}`;
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function documentCentrePagePath(): string {
    return '/document-centre';
}

export function financialSupportAndWellbeingPagePath(): string {
    return '/financial-support-and-wellbeing';
}

export function financialSupportAndWellbeingTopicPagePath(topicId = ':topicId'): string {
    return `/financial-support-and-wellbeing-topic/${topicId}`;
}

export function financialSupportAndWellbeingContentPagePath(contentId = ':contentId'): string {
    return `/financial-support-and-wellbeing-content/${contentId}`;
}

export function requestContactPagePath(initialReason = ':initialReason?'): string {
    return `/request-contact${!!initialReason ? `/${initialReason}` : ''}`;
}

export function faqPagePath(): string {
    return '/faq';
}

export function useOurMatchmakerPagePath(): string {
    return '/use-our-matchmaker';
}

export function legacyCustomerPortalPath(): string {
    return '/customer-portal';
}
