import { TFunction } from 'i18next';
import { CPDate } from '@cp-shared-8/common-utilities';
import { formatAsDate, inputDateFormat } from '@cp-uk/common';
import { MethodsOfPayment, SummaryItem, Views } from '../types';
import { getDateOfPaymentAsCPDate, getDateOfPaymentEffective } from '../helpers';
import { PayInInstalmentsFormValues, PayInInstalmentsSelections } from './types';

export const buildInitialValues = (selections: PayInInstalmentsSelections | undefined): PayInInstalmentsFormValues => {
    if (selections === undefined) {
        return {
            additionalAmount: '',
            methodOfPayment: '',
            dateOfFirstPayment: '',
        };
    }

    const { additionalAmount, methodOfPayment, dateOfFirstPayment } = selections;

    return {
        additionalAmount: additionalAmount.toString(),
        methodOfPayment,
        dateOfFirstPayment: !!dateOfFirstPayment ? formatAsDate(dateOfFirstPayment, inputDateFormat) : '',
    };
};

export const buildSelections = (
    t: TFunction,
    nextScheduledPaymentDate: CPDate | undefined,
    { additionalAmount, methodOfPayment, dateOfFirstPayment }: PayInInstalmentsFormValues,
): PayInInstalmentsSelections => {
    const methodOfPaymentAsString = t(`methodOfPayment.${methodOfPayment}.label`);

    const dateOfFirstPaymentAsCPDate = getDateOfPaymentAsCPDate(dateOfFirstPayment);
    const dateOfFirstPaymentEffective = getDateOfPaymentEffective(
        methodOfPayment,
        dateOfFirstPaymentAsCPDate,
        nextScheduledPaymentDate,
    );

    return {
        additionalAmount: Number(additionalAmount),
        methodOfPayment: methodOfPayment as MethodsOfPayment,
        methodOfPaymentAsString,
        dateOfFirstPayment: dateOfFirstPaymentAsCPDate,
        dateOfFirstPaymentEffective,
    };
};

export const buildSummaryItem = (
    t: TFunction,
    nextScheduledPaymentDate: CPDate | undefined,
    { additionalAmount, methodOfPayment, dateOfFirstPayment }: PayInInstalmentsFormValues,
): SummaryItem => {
    const additionalAmountAsNumber = Number(additionalAmount);
    const dateOfFirstPaymentAsCPDate = getDateOfPaymentAsCPDate(dateOfFirstPayment);
    const dateOfFirstPaymentEffective = getDateOfPaymentEffective(
        methodOfPayment,
        dateOfFirstPaymentAsCPDate,
        nextScheduledPaymentDate,
    );

    return {
        view: Views.payInInstalments,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content', {
            additionalAmount: additionalAmountAsNumber,
            methodOfPayment,
            dateOfFirstPaymentEffective,
        }),
    };
};
