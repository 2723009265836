import React, { useEffect, useRef } from 'react';
import sha1 from 'sha1';
import { chatbotApiKey, currentBrand, currentBronson } from 'config';
import { toBrandCase, useSessionStorage } from 'utils';

export const MERCURY_AI_USER_ID_KEY = 'MercuryAIUserId';

const currentChatbot = {
    baseUrl: 'https://cdn.mercury.ai',
    version: 'v4',
};

const getMercuryBrand = (): string => {
    switch (currentBrand) {
        case 'audi':
        case 'bentley':
        case 'cupra':
        case 'lamborghini':
        case 'porsche':
        case 'seat':
        case 'skoda':
            return toBrandCase(currentBrand);
        case 'vw':
            return 'Volkswagen';
        case 'vwcv':
        case 'vwfs':
            return currentBrand.toUpperCase();
        default:
            alert(`Brand '${currentBrand} is not supported.`);
            return '';
    }
};

export enum ChatbotPage {
    Landing = 'landing',
    Register = 'register',
}

export type ChatbotComponentProps = {
    page: ChatbotPage;
    apiKey: string;
};

export const ChatbotComponent: React.FC<ChatbotComponentProps> = ({ page, apiKey }) => {
    const scriptsDivRef = useRef<HTMLDivElement>(null);
    const linkDivRef = useRef<HTMLDivElement>(null);
    const { getSessionValue, setSessionValue } = useSessionStorage();

    let userId = getSessionValue(MERCURY_AI_USER_ID_KEY);
    if (!userId) {
        const timestamp = new Date().valueOf().toString(36);
        const random1 = Math.random().toString(36).substring(2);
        const random2 = Math.random().toString(36).substring(2);
        userId = sha1(`${currentBrand}|${timestamp}|${random1}|${random2}`);
        setSessionValue(MERCURY_AI_USER_ID_KEY, userId);
    }
    const brand = getMercuryBrand();

    useEffect(() => {
        const inlineScript = document.createElement('script');
        inlineScript.type = 'text/javascript';
        inlineScript.innerHTML = `var mercuryUser = {
    userId: '${userId}',
    context: {
        'global.brand': '${brand}',
        'global.currentPage': '${page}',
    },
};`;

        const externalWidgetScript = document.createElement('script');
        externalWidgetScript.type = 'text/javascript';
        externalWidgetScript.src = `${currentChatbot.baseUrl}/widget/${currentChatbot.version}/widget.js`;
        externalWidgetScript.id = 'mercury-widget-snippet';
        externalWidgetScript.setAttribute('data-key', apiKey || '');

        const externalStylesheetLink = document.createElement('link');
        externalStylesheetLink.rel = 'stylesheet';
        externalStylesheetLink.type = 'text/css';
        externalStylesheetLink.href = `${currentBronson.baseUrl}/3rd-party-ui-extensions/chatbot/latest/${currentChatbot.version}/${currentBronson.theme}/css/widget.css`;

        scriptsDivRef.current?.append(inlineScript);
        scriptsDivRef.current?.append(externalWidgetScript);
        linkDivRef.current?.append(externalStylesheetLink);

        return (): void => {
            document.getElementById('mercury-widget')?.remove();
            document.head.querySelector(`script[src*="${currentChatbot.baseUrl}"]`)?.remove();
            document.head.querySelector(`link[href*="${currentChatbot.baseUrl}"]`)?.remove();
        };
    }, [userId, brand, page, apiKey]);

    return (
        <section className={'c-chatbot'}>
            <div ref={scriptsDivRef} className={'c-chatbot__scripts'} />
            <div ref={linkDivRef} className={'c-chatbot__link'} />
        </section>
    );
};

export type ChatbotProps = {
    page: ChatbotPage;
};

export const Chatbot: React.FC<ChatbotProps> = ({ page }) => {
    if (!chatbotApiKey) {
        return null;
    }

    return <ChatbotComponent page={page} apiKey={chatbotApiKey} />;
};
