import React from 'react';
import { TFunction } from 'i18next';
import { SimpleLink } from 'components/simple-link/SimpleLink';
import { ValidatedSelectItem } from 'components/validated-select/ValidatedSelect';

export const getTelHref = (phoneNumber: string): string => {
    return `tel:${phoneNumber.replace(/<nbsp\/>/g, '')}`;
};

export const buildLinkToPhoneNumber = (
    tOrPhoneNumber: TFunction | string,
    key: string,
    onClick: (() => void) | undefined = undefined,
): JSX.Element => {
    if (typeof tOrPhoneNumber === 'function') {
        const t = tOrPhoneNumber as TFunction;
        const phoneNumber = t(`translation:phoneNumbers.${key}`);
        return buildLinkToPhoneNumber(phoneNumber, key, onClick);
    }

    const phoneNumber = tOrPhoneNumber as string;
    return <SimpleLink href={getTelHref(phoneNumber)} testId={`${key}PhoneNumberLink`} onClick={onClick} />;
};

export const buildPhoneNumberSelectItems = (
    t: TFunction,
    mobileNumber: string | undefined,
    homePhoneNumber: string | undefined,
    workPhoneNumber: string | undefined,
): ValidatedSelectItem[] => {
    const phoneNumbers = {
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    };
    return Object.entries(phoneNumbers)
        .filter(([, phoneNumberValue]) => !!phoneNumberValue)
        .map(([phoneNumberName, phoneNumberValue]) => {
            return {
                label: `${t(`phoneNumbers.${phoneNumberName}`)} - ${phoneNumberValue}`,
                value: phoneNumberValue ?? '',
            };
        });
};
