import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { textWithComponents, buildLinkToPhoneNumber, useAnalyticsActionTracker } from 'utils';

export type RequestAdditionalHelpWrapperProps = {
    className?: string;
    trackingId: string;
    journeyId: string;
};

export const AlternativeNotification: React.FC<RequestAdditionalHelpWrapperProps> = ({
    className,
    trackingId,
    journeyId,
}) => {
    const { t } = useTranslation('request-additional-help');
    const { onAction: onCollectionsPhoneNumberLinkAction } = useAnalyticsActionTracker(trackingId);

    const linkToCollectionsPhoneNumber = buildLinkToPhoneNumber(t, 'collections', () => {
        onCollectionsPhoneNumberLinkAction('collectionsPhoneNumberLink', journeyId);
    });

    return (
        <Notification className={className} status={NotificationStatus.info} testId={'alternativeNotification'}>
            {textWithComponents(t, 'notifications.alternative.text', { linkToCollectionsPhoneNumber })}
        </Notification>
    );
};
