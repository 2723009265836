import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeadTitleMetaElementsPages, useHeadTitleMetaElements } from 'utils';
import { requestAdditionalHelpPagePath } from 'components/navigation/paths';
import { PageHeader } from 'components/page-header/PageHeader';
import {
    RequestAdditionalHelp,
    RequestAdditionalHelpProps,
} from 'components/request-additional-help/RequestAdditionalHelp';

export const RequestAdditionalHelpPage: React.FC = () => {
    useHeadTitleMetaElements(HeadTitleMetaElementsPages.RequestAdditionalHelp);
    const { state } = useLocation<RequestAdditionalHelpProps>();
    const { t } = useTranslation('page-titles');
    const { encryptedContractId, initialOption: initialOptionParam } = useParams<RequestAdditionalHelpProps>();

    if (!!initialOptionParam) {
        return (
            <Redirect
                to={{
                    pathname: requestAdditionalHelpPagePath(encryptedContractId, ''),
                    state: { initialOption: initialOptionParam },
                }}
            />
        );
    }

    const initialOptionState = (state || {}).initialOption;

    return (
        <ContentSection className={'o-content-section--compact uk-request-additional-help-page'} pageWrapSize={'small'}>
            <PageHeader title={t('request-additional-help')} />
            <RequestAdditionalHelp encryptedContractId={encryptedContractId} initialOption={initialOptionState} />
        </ContentSection>
    );
};
