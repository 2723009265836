const notSpecified = '(not specified)';

const getValue = (allFragments: string[], prefix: string): string | undefined => {
    const fragments = allFragments.filter((fragment: string): boolean => fragment.startsWith(prefix));
    if (fragments.length === 0) {
        return undefined;
    }

    const fragment = fragments[0];
    if (!fragment) {
        return undefined;
    }

    return fragment.replace(prefix, '').trim();
};

const buildErrorResponseJson = (response: string, errorMessage?: string): unknown => {
    const allFragments = (response ?? '').split('<BR>');
    const errorValue = getValue(allFragments, 'Error:');
    const messageValue = getValue(allFragments, 'Message:');
    return {
        RESULT: errorValue || '999',
        MESSAGE: messageValue || 'UNKNOWN',
        RESPONSE: response || notSpecified,
        ERROR: errorMessage || notSpecified,
    };
};

export const parseResponseJson = (response: string): unknown => {
    if (!response) {
        return buildErrorResponseJson(response, 'Response is not specified');
    }

    try {
        return JSON.parse(response);
    } catch (error) {
        return buildErrorResponseJson(response, (error as Error).message);
    }
};
