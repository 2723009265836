import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Paragraph } from '@vwfs-bronson/bronson-react';

export type RequestAdditionalHelpWrapperProps = {
    className?: string;
    totalArrears?: number;
};

export const ArrearsBalanceParagraph: React.FC<RequestAdditionalHelpWrapperProps> = ({
    className,
    totalArrears,
}) => {
    const { t } = useTranslation('request-additional-help');

    if ((totalArrears ?? 0) <= 0) {
        return null;
    }

    const classNameList = classNames('u-text-bold', className).trim();

    return (
        <Paragraph className={classNameList} testId={'arrearsBalanceParagraph'}>
            {t('paragraphs.arrearsBalance.content', { totalArrears })}
        </Paragraph>
    );
};
