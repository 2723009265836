import {
    ContractDescriptionBO,
    EarlySettlementExistingBO,
    EarlySettlementGenerateBO,
    EarlySettlementRightOfWithdrawalBO,
    ProposeEarlySettlementResponse,
} from '@cp-uk/common';
import { ProposeCommonPaymentTypical } from '../realex-payments/ExampleData';
import { AxiosRequestConfig } from 'axios';

const contractDescription: ContractDescriptionBO = {
    vehicleBrand: 'VOLKSWAGEN',
    vehicleDescription: 'TIGUAN 2.0TDI 150 4M R-LN T DSG',
    contractNumber: '440123456789',
    registrationNumber: 'EX19ABC',
    productType: 'hirePurchase',
};

export const EarlySettlementRightOfWithdrawal: EarlySettlementRightOfWithdrawalBO = {
    contractDescription,
    state: 'rightOfWithdrawal',
    generateView: undefined,
    enquiryView: undefined,
    completeView: undefined,
};

export const EarlySettlementGenerate: EarlySettlementGenerateBO = {
    contractDescription,
    state: 'generate',
    generateView: {
        encryptedContractId: 'encrypted0000440123456789',
        emailAddress: 'john.smith@gmail.com',
        postalAddress: 'Flat 4, Cromwell House, 123 High Street, Anytown, Anycounty, EG1 2AB',
        otherChangeInProgress: false,
        addressChangeInProgress: false,
        addressChangeByPhone: false,
        productType: 'hirePurchase',
        financeBrand: 'vw',
    },
    enquiryView: undefined,
    completeView: undefined,
};

export const EarlySettlementExisting: EarlySettlementExistingBO = {
    contractDescription,
    state: 'existing',
    generateView: undefined,
    enquiryView: {
        quoteCreationDate: '2020-03-01T00:00:00Z',
        quoteExpiryDate: '2020-03-31T00:00:00Z',
        outstandingBalance: 111.11,
        interestRebateAmount: 222.22,
        arrears: 333.33,
        settlementAmount: 999.99,
    },
    completeView: {
        encryptedContractId: 'encrypted0000440123456789',
        contractNumber: '440123456789',
        settlementAmount: 999.99,
        quoteExpiryDate: '2020-03-31T00:00:00Z',
        payByCardAvailable: 'ok',
    },
};

export const EarlySettlementExistingPayByCardDisabled: EarlySettlementExistingBO = {
    ...EarlySettlementExisting,
    completeView: {
        ...EarlySettlementExisting.completeView,
        payByCardAvailable: 'payByCardDisabled',
    },
};

export const EarlySettlementExistingPaymentManagementSystemNotAvailable: EarlySettlementExistingBO = {
    ...EarlySettlementExisting,
    completeView: {
        ...EarlySettlementExisting.completeView,
        payByCardAvailable: 'paymentManagementSystemNotAvailable',
    },
};

export const EarlySettlementExistingUnpayableArrears: EarlySettlementExistingBO = {
    ...EarlySettlementExisting,
    completeView: {
        ...EarlySettlementExisting.completeView,
        payByCardAvailable: 'unpayableArrears',
    },
};

export const EarlySettlementExistingMaximumPaymentExceeded: EarlySettlementExistingBO = {
    ...EarlySettlementExisting,
    completeView: {
        ...EarlySettlementExisting.completeView,
        payByCardAvailable: 'maximumPaymentExceeded',
    },
};

export const ProposeEarlySettlementTypical = (): ProposeEarlySettlementResponse => {
    return ProposeCommonPaymentTypical();
};

export const proposeEarlySettlementResponse = (_config: AxiosRequestConfig): [number, ProposeEarlySettlementResponse, undefined] => {
    return [200, ProposeEarlySettlementTypical(), undefined];
};
