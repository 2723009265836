import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, ContentSection, Icon, Layout, Paragraph, PageWrap } from '@vwfs-bronson/bronson-react';
import {
    InfoIcon,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    useAuthentication,
} from '@cp-shared-8/frontend-ui';
import { FinanceBrandsMM } from '@cp-uk/common';
import { buildLoginOptions, useFinanceCompanyDetails } from 'utils';
import { MatchmakerInstructionStepper } from 'components/matchmaker-instruction-stepper/MatchmakerInstructionStepper';
import { retailerDescriptionText } from '../helpers';

export const UseOurMatchmaker: React.FC<{ financeBrand: FinanceBrandsMM }> = ({ financeBrand }) => {
    const { t } = useTranslation('use-our-matchmaker');
    const { i18n } = useTranslation();
    const { isAuthenticated, login } = useAuthentication();
    const { companyName, shortCompanyName } = useFinanceCompanyDetails('solutions', financeBrand);

    const { onAction: onLoginAction } = useAnalyticsActionTracker('onMatchmakerLogin');
    useAnalyticsPageViewTracker('useOurMatchmaker', true, !isAuthenticated);

    const retailerDescription = retailerDescriptionText(financeBrand);

    const currentLanguage = i18n.languages[0];

    const onLoginRegisterClick = (): Promise<void> => {
        onLoginAction();
        return login(buildLoginOptions(currentLanguage));
    };

    return (
        <ContentSection>
            <PageWrap size={'small'}>
                <MatchmakerInstructionStepper
                    productType={'solutions'}
                    financeBrand={financeBrand}
                    step={0}
                />
                <Paragraph className={'u-text-center u-mt u-mb-xlarge'}>
                    <Button testId="loginRegisterButton" onClick={onLoginRegisterClick}>
                        {t('buttons.loginRegister')}
                    </Button>
                </Paragraph>
                <Paragraph
                    className={'u-text-center u-text-bold u-text-brand u-mb-small'}
                    testId={'equityCalculationHeadingParagraph'}
                >
                    {t('equityCalculation.heading')}
                </Paragraph>
                <Paragraph className={'u-text-center u-mb-small'} testId={'equityCalculationExplanationParagraph'}>
                    {t('equityCalculation.paragraph')}
                </Paragraph>
                <Card element={'section'} className={'u-mb-small'} testId={'equityCalculationCard'}>
                    <Layout center middle>
                        <Layout.Item className={'u-3/12 u-1/1@s u-text-center'}>
                            <Icon className={'u-mb-xsmall'} name={'semantic-safety-check'} />
                            <Paragraph className={'u-mb-xsmall'} testId={'currentValueTextParagraph'}>
                                {t('calculation.currentValue.text')}
                                <InfoIcon text={t('calculation.currentValue.tooltip')} />
                            </Paragraph>
                            <Paragraph
                                className={'u-text-bold u-text-brand u-mb-none'}
                                testId={'currentValueValueParagraph'}
                            >
                                {t('calculation.currentValue.value')}
                            </Paragraph>
                        </Layout.Item>
                        <Layout.Item className={'u-1/12 u-1/1@s u-text-center'}>
                            <Icon name={'semantic-minus'} />
                        </Layout.Item>
                        <Layout.Item className={'u-3/12 u-1/1@s u-text-center'}>
                            <Icon className={'u-mb-xsmall'} name={'semantic-calculator'} />
                            <Paragraph className={'u-mb-xsmall'} testId={'outstandingFinanceTextParagraph'}>
                                {t('calculation.outstandingFinance.text')}
                                <InfoIcon text={t('calculation.outstandingFinance.tooltip', { companyName })} />
                            </Paragraph>
                            <Paragraph
                                className={'u-text-bold u-text-brand u-mb-none'}
                                testId={'outstandingFinanceValueParagraph'}
                            >
                                {t('calculation.outstandingFinance.value')}
                            </Paragraph>
                        </Layout.Item>
                        <Layout.Item className={'u-1/12 u-1/1@s u-text-center'}>
                            <Icon name={'semantic-equalsign'} />
                        </Layout.Item>
                        <Layout.Item className={'u-3/12 u-1/1@s u-text-center'}>
                            <Icon className={'u-mb-xsmall'} name={'semantic-car-value'} />
                            <Paragraph
                                className={'u-text-bold u-text-brand u-mb-small'}
                                testId={'equityHeadingParagraph'}
                            >
                                {t('calculation.equity.heading')}
                            </Paragraph>
                            <Paragraph className={'u-mb-xsmall'} testId={'equityTextParagraph'}>
                                {t('calculation.equity.text', { shortCompanyName })}
                                <InfoIcon text={t('calculation.equity.tooltip')} />
                            </Paragraph>
                            <Paragraph className={'u-text-bold u-text-brand u-mb-none'} testId={'equityValueParagraph'}>
                                {t('calculation.equity.value')}
                            </Paragraph>
                        </Layout.Item>
                    </Layout>
                </Card>
                <Paragraph className={'u-mb-none'} testId={'disclaimerParagraph'}>
                    {t('disclaimer', { retailerDescription, shortCompanyName })}
                </Paragraph>
            </PageWrap>
        </ContentSection>
    );
};
