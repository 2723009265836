import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { RequestAdditionalHelpBO } from '@cp-uk/common';
import { useScrollTo } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import {
    ContactDetailsNotification,
    ContactDetailsNotificationMode,
    getContactDetailsNotificationMode,
} from 'components/notifications/contact-details/ContactDetailsNotification';
import { fetchRequestAdditionalHelp } from './RequestAdditionalHelpSlice';
import { selectRequestAdditionalHelpForSpecific } from './RequestAdditionalHelpSelector';
import { OptionsAvailableView } from './options-available-view/OptionsAvailableView';
import { Options, OptionsAvailableSelections } from './options-available-view/types';
import { PayInOneGoView } from './pay-in-one-go-view/PayInOneGoView';
import { PayInOneGoSelections } from './pay-in-one-go-view/types';
import { PayInInstalmentsView } from './pay-in-instalments-view/PayInInstalmentsView';
import { PayInInstalmentsSelections } from './pay-in-instalments-view/types';
import { BreathingSpaceView } from './breathing-space-view/BreathingSpaceView';
import { BreathingSpaceSelections } from './breathing-space-view/types';
import { OtherOptionsView } from './other-options-view/OtherOptionsView';
import { OtherOptionsSelections } from './other-options-view/types';
import { IncomeAndExpenditureView } from './income-and-expenditure-view/IncomeAndExpenditureView';
import { IncomeAndExpenditureSelections } from './income-and-expenditure-view/types';
import { AdditionalInformationView } from './additional-information-view/AdditionalInformationView';
import { AdditionalInformationSelections } from './additional-information-view/types';
import { ContactDetailsView } from './contact-details-view/ContactDetailsView';
import { ContactDetailsSelections } from './contact-details-view/types';
import { AdditionalInformationPreviousViews, OptionsAvailableNextViews, SummaryItem, Views } from './types';
import { SummaryAndRequestView } from './summary-and-request-view/SummaryAndRequestView';
import { getInitialOptionFromProp } from './helpers';

export type RequestAdditionalHelpProps = {
    encryptedContractId: string;
    initialOption: string | undefined;
};

const RequestAdditionalHelpUi: React.FC<
    RequestAdditionalHelpProps & { requestAdditionalHelp: RequestAdditionalHelpBO | undefined }
> = ({ encryptedContractId, initialOption, requestAdditionalHelp }) => {
    const initialOptionsAvailableSelections: OptionsAvailableSelections = {
        option: getInitialOptionFromProp(initialOption),
        optionAsString: '',
        consent: false,
    };

    const { t } = useTranslation('request-additional-help');
    const [currentView, setCurrentView] = useState<Views>(Views.optionsAvailable);
    const [currentSummaryItems, setCurrentSummaryItems] = useState<SummaryItem[]>([]);
    const [journeyId, setJourneyId] = useState<string>('');
    const [currentOptionTitle, setCurrentOptionTitle] = useState<string>('');
    const [currentOptionsAvailableSelections, setCurrentOptionsAvailableSelections] = useState<
        OptionsAvailableSelections
    >(initialOptionsAvailableSelections);
    const [currentPayInOneGoSelections, setCurrentPayInOneGoSelections] = useState<PayInOneGoSelections | undefined>(
        undefined,
    );
    const [currentPayInInstalmentsSelections, setCurrentPayInInstalmentsSelections] = useState<
        PayInInstalmentsSelections | undefined
    >(undefined);
    const [currentBreathingSpaceSelections, setCurrentBreathingSpaceSelections] = useState<
        BreathingSpaceSelections | undefined
    >(undefined);
    const [currentOtherOptionsSelections, setCurrentOtherOptionsSelections] = useState<
        OtherOptionsSelections | undefined
    >(undefined);
    const [currentIncomeAndExpenditureSelections, setCurrentIncomeAndExpenditureSelections] = useState<
        IncomeAndExpenditureSelections | undefined
    >(undefined);
    const [currentAdditionalInformationSelections, setCurrentAdditionalInformationSelections] = useState<
        AdditionalInformationSelections | undefined
    >(undefined);
    const [currentContactDetailsSelections, setCurrentContactDetailsSelections] = useState<
        ContactDetailsSelections | undefined
    >(undefined);
    const scrollToRef = useScrollTo(currentView, Views.optionsAvailable);

    if (!requestAdditionalHelp) {
        return null;
    }

    const {
        firstName,
        lastName,
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
        contractDescription,
        totalArrears,
        arrearsStatus,
        changeBankAccountInProgress,
        changePaymentDateInProgress,
        sortCode,
        accountNumber,
        lastBilledPaymentDate,
        nextScheduledPaymentDate,
    } = requestAdditionalHelp;

    const contactDetailsNotificationMode = getContactDetailsNotificationMode(
        emailAddress,
        mobileNumber,
        homePhoneNumber,
        workPhoneNumber,
    );
    if (contactDetailsNotificationMode !== ContactDetailsNotificationMode.CheckContactDetails) {
        return <ContactDetailsNotification mode={contactDetailsNotificationMode} />;
    }

    const defaultRegistrationNumber = t('unknown');
    const defaultSortCode = '**-**-**';
    const defaultAccountNumber = '********';

    if (!contractDescription.registrationNumber) {
        contractDescription.registrationNumber = defaultRegistrationNumber;
    }

    const getNextViewFromOptionsAvailableView = (option: Options): OptionsAvailableNextViews | undefined => {
        let nextView: OptionsAvailableNextViews | undefined = undefined;
        if (option === Options.payInOneGo) {
            nextView = Views.payInOneGo;
        } else if (option === Options.payInInstalments) {
            nextView = Views.payInInstalments;
        } else if (option === Options.breathingSpace) {
            nextView = Views.breathingSpace;
        } else if (option === Options.otherOptions) {
            nextView = Views.otherOptions;
        }

        return nextView;
    };

    const getPreviousViewFromAdditionalInformationView = (): AdditionalInformationPreviousViews | undefined => {
        let previousView: AdditionalInformationPreviousViews | undefined = undefined;
        if (currentPayInOneGoSelections) {
            previousView = Views.payInOneGo;
        } else if (currentPayInInstalmentsSelections) {
            previousView = Views.incomeAndExpenditure;
        } else if (currentBreathingSpaceSelections) {
            previousView = Views.breathingSpace;
        } else if (currentOtherOptionsSelections) {
            previousView = Views.otherOptions;
        }

        return previousView;
    };

    const addSummaryItem = (current: SummaryItem[], summaryItem: SummaryItem): SummaryItem[] => {
        return [...current, summaryItem];
    };

    const removeSummaryItem = (current: SummaryItem[], view: Views): SummaryItem[] => {
        return current.filter((summaryItem) => summaryItem.view !== view);
    };

    const onOptionsAvailableContinue = (
        optionTitle: string,
        selections: OptionsAvailableSelections,
        summaryItem: SummaryItem,
    ): void => {
        const nextView = getNextViewFromOptionsAvailableView(selections.option);
        if (!nextView) {
            // NOTE: Leave this in as testing is done for each of the other "requesting" options:
            // eslint-disable-next-line no-alert
            alert('Unexpected state for onOptionsAvailableContinue() handler!');
            return;
        }

        setCurrentView(nextView);
        setJourneyId(selections.option);
        setCurrentOptionTitle(optionTitle);
        setCurrentOptionsAvailableSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
        if (nextView !== Views.payInOneGo && !!currentPayInOneGoSelections) {
            setCurrentPayInOneGoSelections(undefined);
            setCurrentAdditionalInformationSelections(undefined);
            setCurrentContactDetailsSelections(undefined);
        }
        if (nextView !== Views.payInInstalments && !!currentPayInInstalmentsSelections) {
            setCurrentPayInInstalmentsSelections(undefined);
            setCurrentIncomeAndExpenditureSelections(undefined);
            setCurrentAdditionalInformationSelections(undefined);
            setCurrentContactDetailsSelections(undefined);
        }
        if (nextView !== Views.breathingSpace && !!currentBreathingSpaceSelections) {
            setCurrentBreathingSpaceSelections(undefined);
            setCurrentAdditionalInformationSelections(undefined);
            setCurrentContactDetailsSelections(undefined);
        }
        if (nextView !== Views.otherOptions && !!currentOtherOptionsSelections) {
            setCurrentOtherOptionsSelections(undefined);
            setCurrentAdditionalInformationSelections(undefined);
            setCurrentContactDetailsSelections(undefined);
        }
    };

    const onPayInOneGoBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setJourneyId('');
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onPayInOneGoContinue = (selections: PayInOneGoSelections, summaryItem: SummaryItem): void => {
        setCurrentView(Views.additionalInformation);
        setCurrentPayInOneGoSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onPayInInstalmentsBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setJourneyId('');
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onPayInInstalmentsContinue = (selections: PayInInstalmentsSelections, summaryItem: SummaryItem): void => {
        setCurrentView(Views.incomeAndExpenditure);
        setCurrentPayInInstalmentsSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onBreathingSpaceBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setJourneyId('');
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onBreathingSpaceContinue = (selections: BreathingSpaceSelections, summaryItem: SummaryItem): void => {
        setCurrentView(Views.additionalInformation);
        setCurrentBreathingSpaceSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onOtherOptionsBack = (): void => {
        setCurrentView(Views.optionsAvailable);
        setJourneyId('');
        setCurrentOptionTitle('');
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.optionsAvailable));
    };

    const onOtherOptionsContinue = (selections: OtherOptionsSelections, summaryItem: SummaryItem): void => {
        setCurrentView(Views.additionalInformation);
        setCurrentOtherOptionsSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onIncomeAndExpenditureBack = (): void => {
        setCurrentView(Views.payInInstalments);
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.payInInstalments));
    };

    const onIncomeAndExpenditureContinue = (
        selections: IncomeAndExpenditureSelections,
        summaryItem: SummaryItem,
    ): void => {
        setCurrentView(Views.additionalInformation);
        setCurrentIncomeAndExpenditureSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onAdditionalInformationBack = (): void => {
        const previousView = getPreviousViewFromAdditionalInformationView();
        if (!previousView) {
            // NOTE: Leave this in as testing is done for each of the other "requesting" options:
            // eslint-disable-next-line no-alert
            alert('Unexpected state for onAdditionalInformationBack() handler!');
            return;
        }

        setCurrentView(previousView);
        setCurrentSummaryItems((current) => removeSummaryItem(current, previousView));
    };

    const onAdditionalInformationContinue = (
        selections: AdditionalInformationSelections,
        summaryItem: SummaryItem,
    ): void => {
        setCurrentView(Views.contactDetails);
        setCurrentAdditionalInformationSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onContactDetailsBack = (): void => {
        setCurrentView(Views.additionalInformation);
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.additionalInformation));
    };

    const onContactDetailsContinue = (selections: ContactDetailsSelections, summaryItem: SummaryItem): void => {
        setCurrentView(Views.summaryAndRequest);
        setCurrentContactDetailsSelections(selections);
        setCurrentSummaryItems((current) => addSummaryItem(current, summaryItem));
    };

    const onSummaryAndRequestBack = (): void => {
        setCurrentView(Views.contactDetails);
        setCurrentSummaryItems((current) => removeSummaryItem(current, Views.contactDetails));
    };

    return (
        <Suspense fallback={<Spinner center />}>
            {currentView === Views.optionsAvailable && (
                <ContactDetailsNotification
                    className={'u-mb'}
                    mode={contactDetailsNotificationMode}
                    emailAddress={emailAddress}
                    phoneNumbersListedLaterInJourney
                />
            )}
            <section ref={scrollToRef} className={'c-section--scroll-to-margin-padding'}>
                {currentView === Views.optionsAvailable && (
                    <OptionsAvailableView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        currentSelections={currentOptionsAvailableSelections}
                        onContinue={onOptionsAvailableContinue}
                    />
                )}
                {currentView === Views.payInOneGo && (
                    <PayInOneGoView
                        encryptedContractId={encryptedContractId}
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        arrearsStatus={arrearsStatus}
                        changeBankAccountInProgress={changeBankAccountInProgress}
                        changePaymentDateInProgress={changePaymentDateInProgress}
                        sortCode={sortCode || defaultSortCode}
                        accountNumber={accountNumber || defaultAccountNumber}
                        lastBilledPaymentDate={lastBilledPaymentDate}
                        nextScheduledPaymentDate={nextScheduledPaymentDate}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentPayInOneGoSelections}
                        onBack={onPayInOneGoBack}
                        onContinue={onPayInOneGoContinue}
                    />
                )}
                {currentView === Views.payInInstalments && (
                    <PayInInstalmentsView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        changeBankAccountInProgress={changeBankAccountInProgress}
                        changePaymentDateInProgress={changePaymentDateInProgress}
                        sortCode={sortCode || defaultSortCode}
                        accountNumber={accountNumber || defaultAccountNumber}
                        lastBilledPaymentDate={lastBilledPaymentDate}
                        nextScheduledPaymentDate={nextScheduledPaymentDate}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentPayInInstalmentsSelections}
                        onBack={onPayInInstalmentsBack}
                        onContinue={onPayInInstalmentsContinue}
                    />
                )}
                {currentView === Views.breathingSpace && (
                    <BreathingSpaceView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentBreathingSpaceSelections}
                        onBack={onBreathingSpaceBack}
                        onContinue={onBreathingSpaceContinue}
                    />
                )}
                {currentView === Views.otherOptions && (
                    <OtherOptionsView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentOtherOptionsSelections}
                        onBack={onOtherOptionsBack}
                        onContinue={onOtherOptionsContinue}
                    />
                )}
                {currentView === Views.incomeAndExpenditure && (
                    <IncomeAndExpenditureView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentIncomeAndExpenditureSelections}
                        onBack={onIncomeAndExpenditureBack}
                        onContinue={onIncomeAndExpenditureContinue}
                    />
                )}
                {currentView === Views.additionalInformation && (
                    <AdditionalInformationView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentAdditionalInformationSelections}
                        onBack={onAdditionalInformationBack}
                        onContinue={onAdditionalInformationContinue}
                    />
                )}
                {currentView === Views.contactDetails && (
                    <ContactDetailsView
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        mobileNumber={mobileNumber}
                        homePhoneNumber={homePhoneNumber}
                        workPhoneNumber={workPhoneNumber}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentSelections={currentContactDetailsSelections}
                        onBack={onContactDetailsBack}
                        onContinue={onContactDetailsContinue}
                    />
                )}
                {currentView === Views.summaryAndRequest && (
                    <SummaryAndRequestView
                        encryptedContractId={encryptedContractId}
                        firstName={firstName}
                        lastName={lastName}
                        emailAddress={emailAddress ?? ''}
                        contractDescription={contractDescription}
                        totalArrears={totalArrears}
                        journeyId={journeyId}
                        currentOptionTitle={currentOptionTitle}
                        currentOptionsAvailableSelections={currentOptionsAvailableSelections}
                        currentPayInOneGoSelections={currentPayInOneGoSelections}
                        currentPayInInstalmentsSelections={currentPayInInstalmentsSelections}
                        currentBreathingSpaceSelections={currentBreathingSpaceSelections}
                        currentOtherOptionsSelections={currentOtherOptionsSelections}
                        currentIncomeAndExpenditureSelections={currentIncomeAndExpenditureSelections}
                        currentAdditionalInformationSelections={currentAdditionalInformationSelections}
                        currentContactDetailsSelections={currentContactDetailsSelections}
                        currentSummaryItems={currentSummaryItems}
                        onBack={onSummaryAndRequestBack}
                    />
                )}
            </section>
        </Suspense>
    );
};

const RequestAdditionalHelpWithHandlers = withLoadingAndNoConnectionHandler(RequestAdditionalHelpUi);

export const RequestAdditionalHelp: React.FC<RequestAdditionalHelpProps> = ({ encryptedContractId, initialOption }) => {
    const { data: requestAdditionalHelp, isLoading, loadingError } = useGetContractBasedApiData(
        encryptedContractId,
        fetchRequestAdditionalHelp,
        selectRequestAdditionalHelpForSpecific,
        encryptedContractId,
        true,
    );

    return (
        <RequestAdditionalHelpWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            encryptedContractId={encryptedContractId}
            initialOption={initialOption}
            requestAdditionalHelp={requestAdditionalHelp}
        />
    );
};
