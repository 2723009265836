import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ButtonContainer,
    DescriptionList,
    ProductTable,
    Layout,
    Paragraph,
    SectionHeading,
} from '@vwfs-bronson/bronson-react';
import { InfoIcon, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO, EnquiryViewTerminationBO, formatAsCurrency, formatAsDate } from '@cp-uk/common';
import { textWithComponents } from 'utils';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { View } from 'components/view/View';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { dashboardPagePath } from 'components/navigation/paths';
import { analyticsDiscriminator } from '../constants';

export const EnquiryView: React.FC<{
    contractDescription: ContractDescriptionBO;
    enquiryView: EnquiryViewTerminationBO | undefined;
    onContinue: () => void;
}> = ({ contractDescription, enquiryView, onContinue }) => {
    const { t } = useTranslation('early-termination-enquiry-view');
    const history = useHistory();

    const { onAction: onEarlyTerminationEnquiryPrintAction } = useAnalyticsActionTracker(
        'onEarlySettlementEnquiryPrint',
    );
    useAnalyticsPageViewTracker('earlySettlementEnquiry', !!enquiryView, analyticsDiscriminator);

    if (enquiryView === undefined) {
        return null;
    }

    const {
        quoteCreationDate,
        quoteExpiryDate,
        monthsRemaining,
        outstandingBalance,
        interestRebateAmount,
        creditForEarlyReturn,
        terminationRental,
        vat,
        arrears,
        terminationAmount,
    } = enquiryView;

    const onPrintClick: () => void = () => {
        onEarlyTerminationEnquiryPrintAction(analyticsDiscriminator);
        window.print();
    };

    const titleWithTooltip = (title: string, tooltip: string, isBold = false): React.ReactNode => {
        return (
            <Fragment>
                <span className={isBold ? 'u-text-bold' : undefined}>{title}</span>
                <InfoIcon text={tooltip} />
            </Fragment>
        );
    };

    const productTableSummary: React.ReactNode = (
        <DescriptionList split>
            <DescriptionTermAndDetail
                testId={'terminationAmount'}
                term={titleWithTooltip(
                    t('terminationAmount.title'),
                    t('terminationAmount.tooltip'),
                    true,
                )}
                termClassName={'c-product-table__summary-term'}
                detail={
                    <Fragment>
                        <u>{formatAsCurrency(terminationAmount)}</u>
                        <br />
                        {t('terminationAmount.includingVat')}
                    </Fragment>
                }
                detailClassName={'c-product-table__summary-detail'}
            />
        </DescriptionList>
    );

    return (
        <View testId={'enquiryView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'informationParagraph1'}>{textWithComponents(t, 'paragraphs.information1')}</Paragraph>
            <Layout className={`u-mb`}>
                <Layout.Item default={'5/12'} m={'1/1'}>
                    <DescriptionList testId={'enquiryDates'} split>
                        <DescriptionTermAndDetail
                            testId={'quoteCreationDate'}
                            term={t('quoteCreationDate.title')}
                            detail={formatAsDate(quoteCreationDate)}
                        />
                        <DescriptionTermAndDetail
                            testId={'quoteExpiryDate'}
                            term={titleWithTooltip(t('quoteExpiryDate.title'), t('quoteExpiryDate.tooltip'))}
                            detail={formatAsDate(quoteExpiryDate)}
                        />
                        <DescriptionTermAndDetail
                            testId={'monthsRemaining'}
                            term={titleWithTooltip(t('monthsRemaining.title'), t('monthsRemaining.tooltip'))}
                            detail={monthsRemaining}
                        />
                    </DescriptionList>
                </Layout.Item>
                <Layout.Item default={'7/12'} m={'1/1'}>
                    <ProductTable className={'enquiry-details'} summary={productTableSummary}>
                        <ProductTable.Section>
                            <DescriptionList split>
                                <DescriptionTermAndDetail
                                    testId={'outstandingBalance'}
                                    term={titleWithTooltip(
                                        t('outstandingBalance.title'),
                                        t('outstandingBalance.tooltip'),
                                    )}
                                    detail={formatAsCurrency(outstandingBalance)}
                                />
                                <DescriptionTermAndDetail
                                    testId={'interestRebateAmount'}
                                    term={titleWithTooltip(
                                        t('interestRebateAmount.title'),
                                        t('interestRebateAmount.tooltip'),
                                    )}
                                    detail={formatAsCurrency(interestRebateAmount)}
                                />
                                <DescriptionTermAndDetail
                                    testId={'creditForEarlyReturn'}
                                    term={titleWithTooltip(
                                        t('creditForEarlyReturn.title'),
                                        t('creditForEarlyReturn.tooltip'),
                                    )}
                                    detail={formatAsCurrency(creditForEarlyReturn)}
                                />
                            </DescriptionList>
                        </ProductTable.Section>
                        <ProductTable.Section>
                            <DescriptionList split>
                                <DescriptionTermAndDetail
                                    testId={'terminationRental'}
                                    term={titleWithTooltip(
                                        t('terminationRental.title'),
                                        t('terminationRental.tooltip.'),
                                        true,
                                    )}
                                    detail={formatAsCurrency(terminationRental)}
                                />
                                <DescriptionTermAndDetail
                                    testId={'vat'}
                                    term={titleWithTooltip(t('vat.title'), t('vat.tooltip'))}
                                    detail={formatAsCurrency(vat)}
                                />
                                <DescriptionTermAndDetail
                                    testId={'arrears'}
                                    term={titleWithTooltip(t('arrears.title'), t('arrears.tooltip'))}
                                    detail={formatAsCurrency(arrears)}
                                />
                            </DescriptionList>
                        </ProductTable.Section>
                    </ProductTable>
                </Layout.Item>
            </Layout>
            <Paragraph className={'u-text-muted'} testId={'informationParagraph2'}>
                {textWithComponents(t, 'paragraphs.information2')}
            </Paragraph>
            <ButtonContainer nav>
                <Button
                    type={'button'}
                    testId={'printButton'}
                    icon={'semantic-print'}
                    iconReversed
                    simple
                    small
                    link
                    onClick={onPrintClick}
                >
                    {t('translation:editableSectionNav.print')}
                </Button>
                <Button
                    testId={'returnButton'}
                    type={'button'}
                    onClick={(): void => history.push(dashboardPagePath())}
                    secondary
                >
                    {t('translation:editableSectionNav.return')}
                </Button>
                <Button testId={'continueButton'} type={'button'} onClick={onContinue}>
                    {t('translation:editableSectionNav.continue')}
                </Button>
            </ButtonContainer>
        </View>
    );
};
