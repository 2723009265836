import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { somethingElseMoreDetailIsMandatory } from './helpers';
import { OtherOptionsFormValues, OtherOptions } from './types';

export const validationSchema = (
    t: TFunction,
    maxLengthSomethingElseMoreDetail: number,
): Yup.ObjectSchema<OtherOptionsFormValues> => {
    return Yup.object()
        .shape<OtherOptionsFormValues>({
            otherOptions: Yup.object()
                .shape<OtherOptions>({
                    deferringFirstPaymentUpTo90Days: Yup.boolean().required(),
                    endingAgreementAndOptionsAvailable: Yup.boolean().required(),
                    somethingElse: Yup.boolean().required(),
                })
                .required()
                .test(
                    'atLeastOneSelected',
                    t('otherOptions.validation.atLeastOneSelected'),
                    (otherOptions): boolean => {
                        return (
                            !!otherOptions &&
                            (otherOptions.deferringFirstPaymentUpTo90Days ||
                                otherOptions.endingAgreementAndOptionsAvailable ||
                                otherOptions.somethingElse)
                        );
                    },
                ),
            somethingElseMoreDetail: Yup.string()
                .default('')
                .defined()
                .when('otherOptions', (otherOptions: OtherOptions, schema: Yup.StringSchema<string>) => {
                    if (somethingElseMoreDetailIsMandatory(otherOptions.somethingElse)) {
                        return schema
                            .required(t('somethingElseMoreDetail.validation.required'))
                            .max(maxLengthSomethingElseMoreDetail, t('somethingElseMoreDetail.validation.maxLength'));
                    } else {
                        return schema.length(0, t('somethingElseMoreDetail.validation.mustBeBlank'));
                    }
                }),
        })
        .required();
};
