import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HeroImage as HeroImageShared } from '@cp-shared-8/frontend-ui';
import { textWithComponents, buildLinkToPhoneNumber } from 'utils';
import { landingPagePath } from 'components/navigation/paths';
import { addClassName } from 'components/helpers';

export type SimplePageErrorProps = {
    namespace: string;
};

export const SimplePageError: React.FC<SimplePageErrorProps> = ({ namespace }) => {
    const { t } = useTranslation(namespace);
    const history = useHistory();

    const linkToAvartoPhoneNumber = buildLinkToPhoneNumber(t, 'avarto');

    return (
        <HeroImageShared
            pretitle={t('pretitle')}
            title={t('title')}
            subTitle={textWithComponents(t, 'subTitle', {
                linkToAvartoPhoneNumber: addClassName(linkToAvartoPhoneNumber, 'u-text-inherit-color'),
            })}
            buttonText={t('button')}
            clickHandler={() => history.push(landingPagePath())}
            shallow
        />
    );
};
