import { RequestAdditionalHelpBO } from '@cp-uk/common';
import { ContractDescriptionSolutions } from '../contract-description/ExampleData';
import { Options, OptionsAvailableSelections } from './options-available-view/types';
import { PayInOneGoSelections } from './pay-in-one-go-view/types';
import { PayInInstalmentsSelections } from './pay-in-instalments-view/types';
import { BreathingSpaceSelections } from './breathing-space-view/types';
import { OtherOptionsSelections } from './other-options-view/types';
import { IncomeAndExpenditureSelections } from './income-and-expenditure-view/types';
import { AdditionalInformationSelections } from './additional-information-view/types';
import { AvailableTimes, ContactDetailsSelections } from './contact-details-view/types';
import { PayCommonViewProps, SummaryItem, Views } from './types';

export const RequestAdditionalHelpTypical: RequestAdditionalHelpBO = {
    firstName: 'John',
    lastName: 'Smith',
    emailAddress: 'john.smith@gmail.com',
    mobileNumber: '07890123456',
    homePhoneNumber: '01525123456',
    workPhoneNumber: '01908123456',
    contractDescription: ContractDescriptionSolutions,
    contractNumber: '440123456789',
    registrationNumber: 'EX19ABC',
    productCategory: 'purchase',
    changeBankAccountInProgress: false,
    changePaymentDateInProgress: false,
    sortCode: '*2-*4-*6',
    accountNumber: '*****678',
    lastBilledPaymentDate: '2024-11-30T00:00:00Z',
    nextScheduledPaymentDate: '2024-12-31T00:00:00Z',
    totalArrears: 1234.56,
    arrearsStatus: 'paymentAndHelp',
};

export const RequestAdditionalHelpWithoutEmailAddress: RequestAdditionalHelpBO = {
    ...RequestAdditionalHelpTypical,
    emailAddress: undefined,
};

export const RequestAdditionalHelpWithoutPhoneNumbers: RequestAdditionalHelpBO = {
    ...RequestAdditionalHelpTypical,
    mobileNumber: undefined,
    homePhoneNumber: undefined,
    workPhoneNumber: undefined,
};

export const RequestAdditionalHelpWithoutContactDetails: RequestAdditionalHelpBO = {
    ...RequestAdditionalHelpTypical,
    emailAddress: undefined,
    mobileNumber: undefined,
    homePhoneNumber: undefined,
    workPhoneNumber: undefined,
};

export const OptionsAvailableSelectionsTypical: OptionsAvailableSelections = {
    option: Options.payInOneGo,
    optionAsString: 'options.payInOneGo.title',
    consent: true,
};

export const PayInOneGoSelectionsTypical: PayInOneGoSelections = {
    methodOfPayment: 'directDebit',
    methodOfPaymentAsString: 'methodOfPayment.directDebit.label',
    dateOfPayment: undefined,
    dateOfPaymentEffective: '2024-12-31T00:00:00Z',
};

export const PayInInstalmentsSelectionsTypical: PayInInstalmentsSelections = {
    additionalAmount: 123,
    methodOfPayment: 'directDebit',
    methodOfPaymentAsString: 'methodOfPayment.directDebit.label',
    dateOfFirstPayment: undefined,
    dateOfFirstPaymentEffective: '2024-12-31T00:00:00Z',
};

// NOTE: There are no fields to populate
export const BreathingSpaceSelectionsTypical: BreathingSpaceSelections = {};

export const OtherOptionsSelectionsTypical: OtherOptionsSelections = {
    otherOptions: {
        deferringFirstPaymentUpTo90Days: true,
        endingAgreementAndOptionsAvailable: false,
        somethingElse: true,
    },
    somethingElseMoreDetail: 'some-something-else-more-detail',
};

export const IncomeAndExpenditureSelectionsTypical: IncomeAndExpenditureSelections = {
    income: {
        salaryWages: 90,
        benefitsOther: 10,
        total: 100,
    },
    expenditure: {
        volkswagenFinance: 1,
        rentMortgage: 2,
        waterGasElectric: 3,
        councilTax: 4,
        childCareMaintenance: 5,
        landlineInternetTv: 6,
        mobiles: 7,
        carInsuranceTax: 8,
        fuelTravelCosts: 9,
        housekeeping: 10,
        other: 11,
        otherCreditors: 12,
        total: 78,
    },
    disposableIncome: {
        total: 22,
    },
};

export const AdditionalInformationSelectionsTypical: AdditionalInformationSelections = {
    financialCircumstances: {
        reducedIncome: true,
        unemployment: false,
        healthCondition: true,
        oversightOrMismanagementOfFinances: false,
        behindWithOtherCreditors: false,
        inAnIvaOrFilingForBankruptcy: false,
        other: true,
    },
    financialCircumstancesAsString:
        'financialCircumstances.reducedIncome.label, financialCircumstances.healthCondition.label, financialCircumstances.other.label',
    financialCircumstancesMoreDetail: 'some-financial-circumstances-more-detail',
    additionalSupport: true,
    additionalSupportDetail: 'some-additional-support-detail',
    upToDateWithPriorityBills: false,
};

export const ContactDetailsSelectionsTypical: ContactDetailsSelections = {
    phoneNumber: '07890123456',
    earliestAvailableTime: AvailableTimes.noPreferredEarliest,
    earliestAvailableTimeAsString: 'availableTimes.noPreferredEarliest',
    latestAvailableTime: AvailableTimes.noPreferredLatest,
    latestAvailableTimeAsString: 'availableTimes.noPreferredLatest',
    availableDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
    },
    availableDaysAsString: 'AvailableDays.anyDay',
};

export const SummaryItemsTypical: SummaryItem[] = [
    {
        view: Views.optionsAvailable,
        heading: 'Options available',
        content: "You selected 'Pay in one go'",
    },
    {
        view: Views.payInOneGo,
        heading: 'Overview',
        content: "You chose to pay £1,234.56 by 'Direct debit'...",
    },
    {
        view: Views.additionalInformation,
        heading: 'Additional information',
        content: 'You answered a number of questions...',
    },
    {
        view: Views.contactDetails,
        heading: 'Your contact details',
        content: 'You indicated that we could contact you...',
    },
];

export const PropsChangeBankAccountAndPaymentDateInProgress: Partial<PayCommonViewProps> = {
    changeBankAccountInProgress: true,
    changePaymentDateInProgress: true,
};

export const PropsChangeBankAccountOnlyInProgress: Partial<PayCommonViewProps> = {
    changeBankAccountInProgress: true,
};

export const PropsChangePaymentDateOnlyInProgress: Partial<PayCommonViewProps> = {
    changePaymentDateInProgress: true,
};

export const PropsInBillingPeriod: Partial<PayCommonViewProps> = {
    lastBilledPaymentDate: '2024-12-31T00:00:00Z',
};

export const PropsInLastBillingPeriod: Partial<PayCommonViewProps> = {
    lastBilledPaymentDate: '2024-12-31T00:00:00Z',
    nextScheduledPaymentDate: undefined,
};

export const PropsNoRegularPayments: Partial<PayCommonViewProps> = {
    nextScheduledPaymentDate: undefined,
};

export const PropsNormal: Partial<PayCommonViewProps> = {};
