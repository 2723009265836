import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@vwfs-bronson/bronson-react';
import { useCmsContent } from '@cp-shared-8/frontend-integration';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { DashboardMarketingCardDto } from '@cp-uk/common';
import { appendBuildTimestamp, openInNewWindow, selectAlternativeOrNormal, textAsHtml } from 'utils';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchDashboardMarketingCard } from './DashboardMarketingCardSlice';
import { selectDashboardMarketingCard } from './DashboardMarketingCardSelector';

const MarketingCardUi: React.FC<{ dashboardMarketingCard: DashboardMarketingCardDto | undefined }> = ({
    dashboardMarketingCard,
}) => {
    const history = useHistory();

    const { onAction: onLearnMoreAction } = useAnalyticsActionTracker('onLearnMore');

    if (!dashboardMarketingCard) {
        return null;
    }

    const { normal, alternative, startDate, endDate } = dashboardMarketingCard;
    const alternativeOrNormal = selectAlternativeOrNormal(normal, alternative, startDate, endDate);
    const { imageUrl, title, text, buttonLabel, buttonUrl, button2Label, button2Url } = alternativeOrNormal;

    const buildOnClick = (buttonLabel: string | undefined, buttonUrl: string | undefined): (() => void) => {
        return (): void => {
            onLearnMoreAction(buttonLabel);
            if (buttonUrl?.startsWith('/')) {
                history.push(buttonUrl);
            } else {
                openInNewWindow(buttonUrl);
            }
        };
    };

    const footerContent = (
        <div className={'o-button-container o-button-container--left'}>
            <Button
                type={'button'}
                link
                small
                icon={'semantic-forward'}
                iconReversed
                onClick={buildOnClick(buttonLabel, buttonUrl)}
                testId={'marketingCardButton'}
            >
                {buttonLabel}
            </Button>
            {!!button2Label && !!button2Url && (
                <Button
                    type={'button'}
                    link
                    small
                    icon={'semantic-forward'}
                    iconReversed
                    onClick={buildOnClick(button2Label, button2Url)}
                    testId={'marketingCardButton2'}
                >
                    {button2Label}
                </Button>
            )}
        </div>
    );

    return (
        <Card
            element={'article'}
            imageSrc={appendBuildTimestamp(imageUrl)}
            imageAlt={title}
            title={title}
            footer={true}
            footerContent={footerContent}
            className={'u-text-left'}
            testId={'dashboardMarketingCard'}
        >
            {textAsHtml(text || '')}
        </Card>
    );
};

const MarketingCardWithWrappers = withLoadingAndNoConnectionHandler(MarketingCardUi);

export const DashboardMarketingCard: React.FC = () => {
    const { cmsContent: dashboardMarketingCard, isLoading, loadingError } = useCmsContent(
        fetchDashboardMarketingCard,
        selectDashboardMarketingCard,
    );

    return (
        <MarketingCardWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            dashboardMarketingCard={dashboardMarketingCard}
        />
    );
};
