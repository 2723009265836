import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Fieldset, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO } from '@cp-uk/common';
import { textWithComponents, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { linkToTheRisingCostOfLivingPage, linkToFreeSupportAndIndependentAdvicePage } from 'components/link-to-pages';
import { addOnClick } from 'components/helpers';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { SummaryItem } from '../types';
import { BreathingSpaceSelections } from './types';
import { buildSelections, buildSummaryItem } from './helpers';

export type BreathingSpaceViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number | undefined;
    journeyId: string;
    currentOptionTitle: string;
    currentSelections: BreathingSpaceSelections | undefined;
    onBack: () => void;
    onContinue: (selections: BreathingSpaceSelections, summaryItem: SummaryItem) => void;
};

export const BreathingSpaceView: React.FC<BreathingSpaceViewProps> = ({
    contractDescription,
    totalArrears,
    journeyId,
    currentOptionTitle,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-breathing-space-view');

    const backButtonTestId = 'backButton';
    const continueButtonTestId = 'continueButton';
    const trackingId = 'requestAdditionalHelpBreathingSpace';
    useAnalyticsPageViewTracker(trackingId, !!contractDescription, journeyId);
    const { onAction: onBackAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onContinueAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onFreeSupportAndIndependentAdvicePageLinkAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onTheRisingCostOfLivingPageLinkAction } = useAnalyticsActionTracker(trackingId);

    const onBackClick = (): void => {
        onBackAction(backButtonTestId, journeyId);
        onBack();
    };

    const onContinueClick = (): void => {
        const selections = buildSelections();

        const summaryItem = buildSummaryItem(t);

        onContinueAction(continueButtonTestId, journeyId);
        onContinue(selections, summaryItem);
    };

    const useThisTimeParagraphComponents = {
        linkToFreeSupportAndIndependentAdvicePage: addOnClick(linkToFreeSupportAndIndependentAdvicePage, (): void => {
            onFreeSupportAndIndependentAdvicePageLinkAction('freeSupportAndIndependentAdvicePageLink', journeyId);
        }),
        linkToTheRisingCostOfLivingPage: addOnClick(linkToTheRisingCostOfLivingPage, (): void => {
            onTheRisingCostOfLivingPageLinkAction('theRisingCostOfLivingPageLink', journeyId);
        }),
    };

    return (
        <View testId={'breathingSpaceView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph className={'u-mb-large'} testId={'introductionParagraph'}>
                {textWithComponents(t, 'paragraphs.introduction')}
            </Paragraph>
            <Paragraph testId={'doesNotFeelRightParagraph'}>{t('paragraphs.doesNotFeelRight')}</Paragraph>
            <Paragraph className={'u-mb-large'} testId={'happyToProceedParagraph'}>
                {textWithComponents(t, 'paragraphs.happyToProceed')}
            </Paragraph>
            <Paragraph testId={'useThisTimeParagraph'}>
                {textWithComponents(t, 'paragraphs.useThisTime', useThisTimeParagraphComponents)}
            </Paragraph>
            <Notification
                className={'u-mb'}
                status={NotificationStatus.warning}
                testId={'responsibilityNotification'}
            >
                {t('notifications.responsibility.text')}
            </Notification>
            <Fieldset>
                <Fieldset.Row>
                    <ButtonContainer nav>
                        <Button type={'button'} testId={backButtonTestId} onClick={onBackClick} secondary>
                            {t('translation:editableSectionNav.back')}
                        </Button>
                        <Button type={'button'} testId={continueButtonTestId} onClick={onContinueClick}>
                            {t('translation:editableSectionNav.continue')}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
                <Fieldset.Row>
                    <AlternativeNotification trackingId={trackingId} journeyId={journeyId} />
                </Fieldset.Row>
            </Fieldset>
        </View>
    );
};
