import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ButtonContainer,
    DescriptionList,
    Fieldset,
    Modal,
    Paragraph,
    SectionHeading,
} from '@vwfs-bronson/bronson-react';
import {
    ContractDescriptionBO,
    getRequestBreathingSpaceEndpoint,
    getRequestOtherOptionsEndpoint,
    getRequestPayInInstalmentsEndpoint,
    getRequestPayInOneGoEndpoint,
} from '@cp-uk/common';
import { CpDataApi } from 'cp-xhr';
import { textWithComponents, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { DescriptionTermAndDetail } from 'components/description-term-and-detail';
import { dashboardPagePath } from 'components/navigation/paths';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { OptionsAvailableSelections } from '../options-available-view/types';
import { PayInOneGoSelections } from '../pay-in-one-go-view/types';
import { PayInInstalmentsSelections } from '../pay-in-instalments-view/types';
import { BreathingSpaceSelections } from '../breathing-space-view/types';
import { OtherOptionsSelections } from '../other-options-view/types';
import { IncomeAndExpenditureSelections } from '../income-and-expenditure-view/types';
import { AdditionalInformationSelections } from '../additional-information-view/types';
import { ContactDetailsSelections } from '../contact-details-view/types';
import { SummaryItem } from '../types';
import {
    buildRequestBreathingSpace,
    buildRequestOtherOptions,
    buildRequestPayInInstalments,
    buildRequestPayInOneGo,
} from './helpers';

export type SummaryAndRequestViewProps = {
    encryptedContractId: string;
    firstName: string | undefined;
    lastName: string | undefined;
    emailAddress: string;
    contractDescription: ContractDescriptionBO;
    totalArrears: number | undefined;
    journeyId: string;
    currentOptionTitle: string;
    currentOptionsAvailableSelections: OptionsAvailableSelections | undefined;
    currentPayInOneGoSelections: PayInOneGoSelections | undefined;
    currentPayInInstalmentsSelections: PayInInstalmentsSelections | undefined;
    currentBreathingSpaceSelections: BreathingSpaceSelections | undefined;
    currentOtherOptionsSelections: OtherOptionsSelections | undefined;
    currentIncomeAndExpenditureSelections: IncomeAndExpenditureSelections | undefined;
    currentAdditionalInformationSelections: AdditionalInformationSelections | undefined;
    currentContactDetailsSelections: ContactDetailsSelections | undefined;
    currentSummaryItems: SummaryItem[];
    onBack: () => void;
};

export const SummaryAndRequestView: React.FC<SummaryAndRequestViewProps> = ({
    encryptedContractId,
    firstName,
    lastName,
    emailAddress,
    contractDescription,
    totalArrears,
    journeyId,
    currentOptionTitle,
    currentOptionsAvailableSelections,
    currentPayInOneGoSelections,
    currentPayInInstalmentsSelections,
    currentBreathingSpaceSelections,
    currentOtherOptionsSelections,
    currentIncomeAndExpenditureSelections,
    currentAdditionalInformationSelections,
    currentContactDetailsSelections,
    currentSummaryItems,
    onBack,
}) => {
    const { t } = useTranslation('request-additional-help-summary-and-request-view');
    const history = useHistory();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [trackingId, setTrackingId] = useState<string>('requestAdditionalHelpSummaryAndRequest');

    const backButtonTestId = 'backButton';
    const requestButtonTestId = 'requestButton';
    useAnalyticsPageViewTracker(trackingId, !!contractDescription, journeyId);
    const { onAction: onBackAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onRequestAction } = useAnalyticsActionTracker(trackingId);

    const optionTitle = currentOptionTitle.toLowerCase();
    const optionFragment = optionTitle.startsWith('pay') ? 'to' : 'for';

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onIgnoreClose = (): void => {};

    const onSuccessModalConfirm = (): void => {
        history.push(dashboardPagePath());
    };

    const onErrorModalConfirm = (): void => {
        setTrackingId('requestAdditionalHelpSummaryAndRequest');
        setShowErrorModal(false);
    };

    const onBackClick = (): void => {
        onBackAction(backButtonTestId, journeyId)
        onBack();
    };

    const onRequestClick = async (): Promise<void> => {
        onRequestAction(requestButtonTestId, journeyId);

        // NOTE: Deliberately keeping the "switching" and the "posting" separate...
        let endpoint: string | undefined = undefined;
        let request: unknown | undefined = undefined;

        if (
            currentOptionsAvailableSelections &&
            currentPayInOneGoSelections &&
            currentAdditionalInformationSelections &&
            currentContactDetailsSelections
        ) {
            endpoint = getRequestPayInOneGoEndpoint(encryptedContractId);
            request = buildRequestPayInOneGo(
                firstName,
                lastName,
                emailAddress,
                currentOptionsAvailableSelections,
                currentPayInOneGoSelections,
                currentAdditionalInformationSelections,
                currentContactDetailsSelections,
            );
        } else if (
            currentOptionsAvailableSelections &&
            currentPayInInstalmentsSelections &&
            currentIncomeAndExpenditureSelections &&
            currentAdditionalInformationSelections &&
            currentContactDetailsSelections
        ) {
            endpoint = getRequestPayInInstalmentsEndpoint(encryptedContractId);
            request = buildRequestPayInInstalments(
                firstName,
                lastName,
                emailAddress,
                currentOptionsAvailableSelections,
                currentPayInInstalmentsSelections,
                currentIncomeAndExpenditureSelections,
                currentAdditionalInformationSelections,
                currentContactDetailsSelections,
            );
        } else if (
            currentOptionsAvailableSelections &&
            currentBreathingSpaceSelections &&
            currentAdditionalInformationSelections &&
            currentContactDetailsSelections
        ) {
            endpoint = getRequestBreathingSpaceEndpoint(encryptedContractId);
            request = buildRequestBreathingSpace(
                firstName,
                lastName,
                emailAddress,
                currentOptionsAvailableSelections,
                currentBreathingSpaceSelections,
                currentAdditionalInformationSelections,
                currentContactDetailsSelections,
            );
        } else if (
            currentOptionsAvailableSelections &&
            currentOtherOptionsSelections &&
            currentAdditionalInformationSelections &&
            currentContactDetailsSelections
        ) {
            endpoint = getRequestOtherOptionsEndpoint(encryptedContractId);
            request = buildRequestOtherOptions(
                firstName,
                lastName,
                emailAddress,
                currentOptionsAvailableSelections,
                currentOtherOptionsSelections,
                currentAdditionalInformationSelections,
                currentContactDetailsSelections,
            );
        }

        if (!endpoint || !request) {
            // NOTE: Leave this in as testing is done for each of the other "requesting" options:
            // eslint-disable-next-line no-alert
            alert('Unexpected state for onRequestClick() handler!');
            return Promise.resolve();
        }

        setIsSubmitting(true);
        return CpDataApi.post<void>(endpoint, request)
            .then(() => {
                setTrackingId('requestAdditionalHelpSummaryAndRequestSuccess');
                setIsSubmitting(false);
                setShowSuccessModal(true);
            })
            .catch(() => {
                setTrackingId('requestAdditionalHelpSummaryAndRequestFailure');
                setIsSubmitting(false);
                setShowErrorModal(true);
            });
    };

    return (
        <View testId={'summaryAndRequestView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Fieldset>
                <Fieldset.Row>
                    <Paragraph testId={'introductionParagraph'}>
                        {textWithComponents(t, 'paragraphs.introduction')}
                    </Paragraph>
                </Fieldset.Row>
                <Fieldset.Row>
                    <DescriptionList testId={'summaryItems'}>
                        {currentSummaryItems.map(({ heading, content }: SummaryItem, index: number) => {
                            return (
                                <DescriptionTermAndDetail
                                    key={index}
                                    testId={`summaryItem${index}`}
                                    term={heading}
                                    termClassName={'u-text-bold'}
                                    detail={content}
                                    detailClassName={'u-text-normal'}
                                />
                            );
                        })}
                    </DescriptionList>
                </Fieldset.Row>
                <Fieldset.Row>
                    <Paragraph className={'u-text-bold'} testId={'nextStepsParagraph'}>
                        {t('paragraphs.nextSteps')}
                    </Paragraph>
                </Fieldset.Row>
                <Fieldset.Row>
                    <ButtonContainer nav>
                        <Button
                            type={'button'}
                            disabled={isSubmitting}
                            testId={backButtonTestId}
                            onClick={onBackClick}
                            secondary
                        >
                            {t('translation:editableSectionNav.back')}
                        </Button>
                        <Button
                            type={'button'}
                            disabled={isSubmitting}
                            testId={requestButtonTestId}
                            onClick={onRequestClick}
                        >
                            {t('translation:editableSectionNav.request')}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
                <Fieldset.Row>
                    <AlternativeNotification trackingId={trackingId} journeyId={journeyId} />
                </Fieldset.Row>
            </Fieldset>
            <Modal
                shown={showSuccessModal}
                status={'success'}
                title={t('successModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.dashboard')}
                buttonConfirmType={'button'}
                testId={'successModal'}
                onClose={onIgnoreClose}
                onConfirm={onSuccessModalConfirm}
            >
                <Paragraph>{t('successModal.contents', { optionFragment, optionTitle })}</Paragraph>
            </Modal>
            <Modal
                shown={showErrorModal}
                status={'error'}
                title={t('errorModal.title')}
                closeAny={false}
                hideCloseButton={true}
                buttonConfirmText={t('translation:editableSectionNav.close')}
                buttonConfirmType={'button'}
                testId={'errorModal'}
                onClose={onIgnoreClose}
                onConfirm={onErrorModalConfirm}
            >
                <Paragraph>{t('errorModal.contents')}</Paragraph>
            </Modal>
        </View>
    );
};
