import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { additionalSupportDetailIsMandatory, financialCircumstancesMoreDetailIsMandatory } from './helpers';
import { AdditionalInformationFormValues, Buttons, FinancialCircumstances } from './types';

const noOrYes: string[] = [Buttons.no, Buttons.yes];

export const validationSchema = (
    t: TFunction,
    maxLengthFinancialCircumstancesMoreDetail: number,
    maxLengthAdditionalSupportDetail: number,
): Yup.ObjectSchema<AdditionalInformationFormValues> => {
    return Yup.object()
        .shape<AdditionalInformationFormValues>({
            financialCircumstances: Yup.object()
                .shape<FinancialCircumstances>({
                    reducedIncome: Yup.boolean().required(),
                    unemployment: Yup.boolean().required(),
                    healthCondition: Yup.boolean().required(),
                    oversightOrMismanagementOfFinances: Yup.boolean().required(),
                    behindWithOtherCreditors: Yup.boolean().required(),
                    inAnIvaOrFilingForBankruptcy: Yup.boolean().required(),
                    other: Yup.boolean().required(),
                })
                .required()
                .test(
                    'atLeastOneSelected',
                    t('financialCircumstances.validation.atLeastOneSelected'),
                    (financialCircumstances): boolean => {
                        return (
                            !!financialCircumstances &&
                            (financialCircumstances.reducedIncome ||
                                financialCircumstances.unemployment ||
                                financialCircumstances.healthCondition ||
                                financialCircumstances.oversightOrMismanagementOfFinances ||
                                financialCircumstances.behindWithOtherCreditors ||
                                financialCircumstances.inAnIvaOrFilingForBankruptcy ||
                                financialCircumstances.other)
                        );
                    },
                ),
            financialCircumstancesMoreDetail: Yup.string()
                .default('')
                .defined()
                .max(maxLengthFinancialCircumstancesMoreDetail, t('financialCircumstancesMoreDetail.validation.maxLength'))
                .when(
                    'financialCircumstances',
                    (financialCircumstances: FinancialCircumstances, schema: Yup.StringSchema<string>) => {
                        if (financialCircumstancesMoreDetailIsMandatory(financialCircumstances.other)) {
                            return schema.required(t('financialCircumstancesMoreDetail.validation.required'));
                        } else {
                            return schema;
                        }
                    },
                ),
            additionalSupport: Yup.string()
                .required(t('noOrYesRadioButtons.validation.required'))
                .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
            additionalSupportDetail: Yup.string()
                .default('')
                .defined()
                .when('additionalSupport', (additionalSupport: string, schema: Yup.StringSchema<string>) => {
                    if (additionalSupportDetailIsMandatory(additionalSupport as Buttons)) {
                        return schema
                            .required(t('additionalSupportDetail.validation.required'))
                            .max(maxLengthAdditionalSupportDetail, t('additionalSupportDetail.validation.maxLength'));
                    } else {
                        return schema.length(0, t('additionalSupportDetail.validation.mustBeBlank'));
                    }
                }),
            upToDateWithPriorityBills: Yup.string()
                .required(t('noOrYesRadioButtons.validation.required'))
                .oneOf(noOrYes, t('noOrYesRadioButtons.validation.required')),
        })
        .required();
};
