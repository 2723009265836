import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { ContractDescriptionBO } from '@cp-uk/common';
import { textWithComponents, useAnalyticsActionTracker, useAnalyticsPageViewTracker} from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import {
    TileSelectProps,
    ValidatedTileSelectGroup,
} from 'components/validated-tile-select-group/ValidatedTileSelectGroup';
import { ValidatedCheckbox } from 'components/validated-checkbox/ValidatedCheckbox';
import { linkToPrivacyPolicyPage } from 'components/link-to-pages';
import { dashboardPagePath } from 'components/navigation/paths';
import { addOnClick } from 'components/helpers';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { SummaryItem } from '../types';
import { validationSchema } from './OptionsAvailableValidation';
import { Options, OptionsAvailableFormValues, OptionsAvailableSelections } from './types';
import { buildInitialValues, buildSelections, buildSummaryItem } from './helpers';

export type OptionsAvailableViewProps = {
    contractDescription: ContractDescriptionBO;
    totalArrears: number | undefined;
    currentSelections: OptionsAvailableSelections;
    onContinue: (optionTitle: string, selections: OptionsAvailableSelections, summaryItem: SummaryItem) => void;
};

export const OptionsAvailableView: React.FC<OptionsAvailableViewProps> = ({
    contractDescription,
    totalArrears,
    currentSelections,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-options-available-view');
    const history = useHistory();

    const continueButtonTestId = 'continueButton';
    const returnButtonTestId = 'returnButton';
    const trackingId = 'requestAdditionalHelpOptionsAvailable';
    useAnalyticsPageViewTracker(trackingId, !!contractDescription);
    const { onAction: onReturnAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onContinueAction } = useAnalyticsActionTracker(trackingId);
    const { onAction: onPrivacyPolicyPageLinkAction } = useAnalyticsActionTracker(trackingId);

    const optionTileSelects: TileSelectProps[] = [
        {
            icon: 'semantic-premium-s',
            title: t('options.payInOneGo.title'),
            text: t('options.payInOneGo.text'),
            value: Options.payInOneGo,
        },
        {
            icon: 'semantic-premium-l',
            title: t('options.payInInstalments.title'),
            text: t('options.payInInstalments.text'),
            value: Options.payInInstalments,
        },
        {
            icon: 'semantic-clock',
            title: t('options.breathingSpace.title'),
            text: t('options.breathingSpace.text'),
            value: Options.breathingSpace,
        },
        {
            icon: 'semantic-faq',
            title: t('options.otherOptions.title'),
            text: t('options.otherOptions.text'),
            value: Options.otherOptions,
        },
    ];

    const initialValues = buildInitialValues(currentSelections);

    const onReturnClick = (): void => {
        onReturnAction(returnButtonTestId);
        history.push(dashboardPagePath());
    };

    const consentParagraphComponents = {
        linkToPrivacyPolicyPage: addOnClick(linkToPrivacyPolicyPage, () => {
            onPrivacyPolicyPageLinkAction('privacyPolicyPageLink');
        }),
    };

    return (
        <View testId={'optionsAvailableView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading')}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph testId={'introductionParagraph'}>{textWithComponents(t, 'paragraphs.introduction')}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={(values): void => {
                    const selections = buildSelections(t, values);

                    const optionTitle = t(`options.${selections.option}.title`);

                    const summaryItem = buildSummaryItem(t, selections.option);

                    onContinueAction(continueButtonTestId, selections.option);
                    onContinue(optionTitle, selections, summaryItem);
                }}
            >
                {(formik: FormikProps<OptionsAvailableFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-options-available-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedTileSelectGroup
                                    layoutItemClassName={'u-1/4 u-1/1@m'}
                                    inputType={'radio'}
                                    tileSelects={optionTileSelects}
                                    name={'option'}
                                    testId={'optionTileSelects'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={t('consent.label')}
                                    name={'consent'}
                                    testId={'consent'}
                                    isMandatory={true}
                                />
                                <Paragraph
                                    className={'u-text-muted u-indent u-mt-xsmall u-mb-none'}
                                    testId={'consentDescriptionParagraph'}
                                >
                                    {textWithComponents(t, 'consent.description', consentParagraphComponents)}
                                </Paragraph>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button type={'button'} testId={returnButtonTestId} onClick={onReturnClick} secondary>
                                        {t('translation:editableSectionNav.return')}
                                    </Button>
                                    <Button type={'button'} testId={continueButtonTestId} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification trackingId={trackingId} journeyId={''} />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
