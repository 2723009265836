import { TFunction } from 'i18next';
import { CPDate } from '@cp-shared-8/common-utilities';
import { formatAsDate, inputDateFormat } from '@cp-uk/common';
import { MethodsOfPayment, SummaryItem, Views } from '../types';
import { getDateOfPaymentAsCPDate, getDateOfPaymentEffective } from '../helpers'
import { PayInOneGoFormValues, PayInOneGoSelections } from './types';

export const buildInitialValues = (selections: PayInOneGoSelections | undefined): PayInOneGoFormValues => {
    if (selections === undefined) {
        return {
            methodOfPayment: '',
            dateOfPayment: '',
        };
    }

    const { methodOfPayment, dateOfPayment } = selections;

    return {
        methodOfPayment: methodOfPayment,
        dateOfPayment: !!dateOfPayment ? formatAsDate(dateOfPayment, inputDateFormat) : '',
    };
};

export const buildSelections = (
    t: TFunction,
    nextScheduledPaymentDate: CPDate | undefined,
    { methodOfPayment, dateOfPayment }: PayInOneGoFormValues,
): PayInOneGoSelections => {
    const methodOfPaymentAsString = t(`methodOfPayment.${methodOfPayment}.label`);

    const dateOfPaymentAsCPDate = getDateOfPaymentAsCPDate(dateOfPayment);
    const dateOfPaymentEffective = getDateOfPaymentEffective(
        methodOfPayment,
        dateOfPaymentAsCPDate,
        nextScheduledPaymentDate,
    );

    return {
        methodOfPayment: methodOfPayment as MethodsOfPayment,
        methodOfPaymentAsString,
        dateOfPayment: dateOfPaymentAsCPDate,
        dateOfPaymentEffective,
    };
};

export const buildSummaryItem = (
    t: TFunction,
    totalArrears: number | undefined,
    nextScheduledPaymentDate: CPDate | undefined,
    { methodOfPayment, dateOfPayment }: PayInOneGoFormValues,
): SummaryItem => {
    const dateOfPaymentAsCPDate = getDateOfPaymentAsCPDate(dateOfPayment);
    const dateOfPaymentEffective = getDateOfPaymentEffective(
        methodOfPayment,
        dateOfPaymentAsCPDate,
        nextScheduledPaymentDate,
    );

    return {
        view: Views.payInOneGo,
        heading: t('summaryItem.heading'),
        content: t('summaryItem.content', { totalArrears, methodOfPayment, dateOfPaymentEffective }),
    };
};
