import React, { Fragment } from 'react';
import { Paragraph, InstructionStepper } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { carOrVehicleText, retailerDescriptionText } from '../helpers';
import { useFinanceCompanyDetails } from '../../utils';
import { FinanceBrandsMM, ProductTypesMM } from '@cp-uk/common';

export const MatchmakerInstructionStepper: React.FC<{
    productType: ProductTypesMM;
    financeBrand: FinanceBrandsMM;
    step: number;
}> = ({ financeBrand, productType, step }) => {
    const { t } = useTranslation('matchmaker-instruction-stepper');
    const { shortCompanyName } = useFinanceCompanyDetails(productType, financeBrand);

    const carOrVehicle = carOrVehicleText(financeBrand);
    const retailerDescription = retailerDescriptionText(financeBrand);

    const getStateForStep = (index: number): string => {
        return step >= index ? 'is-active' : '';
    };

    return (
        <Fragment>
            <Paragraph
                testId={'instructionStepperHeadingParagraph'}
                className={'u-text-center u-text-bold u-text-brand'}
            >
                {t('heading', { shortCompanyName })}
            </Paragraph>
            <InstructionStepper testId={'instructionStepper'}>
                <InstructionStepper.Step
                    key={'step1'}
                    data-testid={'step1'}
                    title={t('step1.title')}
                    icon={'semantic-user'}
                    state={getStateForStep(1)}
                >
                    {t('step1.content')}
                </InstructionStepper.Step>
                <InstructionStepper.Step
                    key={'step2'}
                    data-testid={'step2'}
                    title={t('step2.title')}
                    icon={'semantic-search'}
                    state={getStateForStep(2)}
                >
                    {t('step2.content')}
                </InstructionStepper.Step>
                <InstructionStepper.Step
                    key={'step3'}
                    data-testid={'step3'}
                    title={t('step3.title')}
                    icon={'semantic-car'}
                    state={getStateForStep(3)}
                >
                    {t('step3.content')}
                </InstructionStepper.Step>
                <InstructionStepper.Step
                    key={'step4'}
                    data-testid={'step4'}
                    title={t('step4.title')}
                    icon={'semantic-car-value'}
                    state={getStateForStep(4)}
                >
                    {t('step4.content', { carOrVehicle })}
                </InstructionStepper.Step>
                <InstructionStepper.Step
                    key={'step5'}
                    data-testid={'step5'}
                    title={t('step5.title')}
                    icon={'semantic-phone'}
                    state={getStateForStep(5)}
                >
                    {t('step5.content', { shortCompanyName, retailerDescription })}
                </InstructionStepper.Step>
            </InstructionStepper>
        </Fragment>
    );
};
