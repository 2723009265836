import {
    RequestBreathingSpace,
    RequestFragmentAdditionalInformation,
    RequestFragmentBreathingSpace,
    RequestFragmentCommon,
    RequestFragmentContactDetails,
    RequestFragmentIncomeAndExpenditure,
    RequestFragmentOptionsAvailable,
    RequestFragmentOtherOptions,
    RequestFragmentPayInInstalments,
    RequestFragmentPayInOneGo,
    RequestPayInInstalments,
    RequestPayInOneGo,
} from '@cp-uk/common';
import { OptionsAvailableSelections } from '../options-available-view/types';
import { PayInOneGoSelections } from '../pay-in-one-go-view/types';
import { AdditionalInformationSelections } from '../additional-information-view/types';
import { ContactDetailsSelections } from '../contact-details-view/types';
import { PayInInstalmentsSelections } from '../pay-in-instalments-view/types';
import { IncomeAndExpenditureSelections } from '../income-and-expenditure-view/types';
import { BreathingSpaceSelections } from '../breathing-space-view/types';
import { OtherOptionsSelections } from '../other-options-view/types';

const buildRequestFragmentCommon = (
    firstName: string | undefined,
    lastName: string | undefined,
    emailAddress: string,
): RequestFragmentCommon => {
    return {
        firstName,
        lastName,
        emailAddress,
    };
};

const buildRequestFragmentOptionsAvailable = ({
    optionAsString,
    consent,
}: OptionsAvailableSelections): RequestFragmentOptionsAvailable => {
    return {
        option: optionAsString,
        consent: consent,
    };
};

const buildRequestFragmentPayInOneGo = ({
    methodOfPaymentAsString,
    dateOfPaymentEffective,
}: PayInOneGoSelections): RequestFragmentPayInOneGo => {
    return {
        methodOfPayment: methodOfPaymentAsString,
        dateOfPayment: dateOfPaymentEffective,
    };
};

const buildRequestFragmentPayInInstalments = ({
    additionalAmount,
    methodOfPaymentAsString,
    dateOfFirstPaymentEffective,
}: PayInInstalmentsSelections): RequestFragmentPayInInstalments => {
    return {
        additionalAmount,
        methodOfPayment: methodOfPaymentAsString,
        dateOfFirstPayment: dateOfFirstPaymentEffective,
    };
};

const buildRequestFragmentBreathingSpace = (_: BreathingSpaceSelections): RequestFragmentBreathingSpace => {
    // NOTE: There are no fields to populate
    return {};
};

const buildRequestFragmentOtherOptions = ({
    otherOptions: { deferringFirstPaymentUpTo90Days, endingAgreementAndOptionsAvailable, somethingElse },
    somethingElseMoreDetail,
}: OtherOptionsSelections): RequestFragmentOtherOptions => {
    return {
        deferringFirstPaymentUpTo90Days,
        endingAgreementAndOptionsAvailable,
        somethingElse,
        somethingElseMoreDetail,
    };
};

const buildRequestFragmentIncomeAndExpenditure = ({
    income: { salaryWages, benefitsOther, total: incomeTotal },
    expenditure: {
        volkswagenFinance,
        rentMortgage,
        waterGasElectric,
        councilTax,
        childCareMaintenance,
        landlineInternetTv,
        mobiles,
        carInsuranceTax,
        fuelTravelCosts,
        housekeeping,
        other,
        otherCreditors,
        total: expenditureTotal,
    },
    disposableIncome: { total: disposableIncomeTotal },
}: IncomeAndExpenditureSelections): RequestFragmentIncomeAndExpenditure => {
    return {
        incomeSalaryWages: salaryWages,
        incomeBenefitsOther: benefitsOther,
        incomeTotal,
        expenditureVolkswagenFinance: volkswagenFinance,
        expenditureRentMortgage: rentMortgage,
        expenditureWaterGasElectric: waterGasElectric,
        expenditureCouncilTax: councilTax,
        expenditureChildCareMaintenance: childCareMaintenance,
        expenditureLandlineInternetTv: landlineInternetTv,
        expenditureMobiles: mobiles,
        expenditureCarInsuranceTax: carInsuranceTax,
        expenditureFuelTravelCosts: fuelTravelCosts,
        expenditureHousekeeping: housekeeping,
        expenditureOther: other,
        expenditureOtherCreditors: otherCreditors,
        expenditureTotal,
        disposableIncomeTotal,
    };
};

const buildRequestFragmentAdditionalInformation = ({
    financialCircumstancesAsString,
    financialCircumstancesMoreDetail,
    additionalSupport,
    additionalSupportDetail,
    upToDateWithPriorityBills,
}: AdditionalInformationSelections): RequestFragmentAdditionalInformation => {
    return {
        financialCircumstances: financialCircumstancesAsString,
        financialCircumstancesMoreDetail,
        additionalSupport,
        additionalSupportDetail,
        upToDateWithPriorityBills,
    };
};

const buildRequestFragmentContactDetails = ({
    phoneNumber,
    earliestAvailableTimeAsString,
    latestAvailableTimeAsString,
    availableDaysAsString,
}: ContactDetailsSelections): RequestFragmentContactDetails => {
    return {
        phoneNumber,
        earliestAvailableTime: earliestAvailableTimeAsString,
        latestAvailableTime: latestAvailableTimeAsString,
        availableDays: availableDaysAsString,
    };
};

export const buildRequestPayInOneGo = (
    firstName: string | undefined,
    lastName: string | undefined,
    emailAddress: string,
    optionsAvailableSelections: OptionsAvailableSelections,
    payInOneGoSelections: PayInOneGoSelections,
    additionalInformationSelections: AdditionalInformationSelections,
    contactDetailsSelections: ContactDetailsSelections,
): RequestPayInOneGo => {
    return {
        ...buildRequestFragmentCommon(firstName, lastName, emailAddress),
        ...buildRequestFragmentOptionsAvailable(optionsAvailableSelections),
        ...buildRequestFragmentPayInOneGo(payInOneGoSelections),
        ...buildRequestFragmentAdditionalInformation(additionalInformationSelections),
        ...buildRequestFragmentContactDetails(contactDetailsSelections),
    };
};

export const buildRequestPayInInstalments = (
    firstName: string | undefined,
    lastName: string | undefined,
    emailAddress: string,
    optionsAvailableSelections: OptionsAvailableSelections,
    payInInstalmentsSelections: PayInInstalmentsSelections,
    incomeAndExpenditureSelections: IncomeAndExpenditureSelections,
    additionalInformationSelections: AdditionalInformationSelections,
    contactDetailsSelections: ContactDetailsSelections,
): RequestPayInInstalments => {
    return {
        ...buildRequestFragmentCommon(firstName, lastName, emailAddress),
        ...buildRequestFragmentOptionsAvailable(optionsAvailableSelections),
        ...buildRequestFragmentPayInInstalments(payInInstalmentsSelections),
        ...buildRequestFragmentIncomeAndExpenditure(incomeAndExpenditureSelections),
        ...buildRequestFragmentAdditionalInformation(additionalInformationSelections),
        ...buildRequestFragmentContactDetails(contactDetailsSelections),
    };
};

export const buildRequestBreathingSpace = (
    firstName: string | undefined,
    lastName: string | undefined,
    emailAddress: string,
    optionsAvailableSelections: OptionsAvailableSelections,
    breathingSpaceSelections: BreathingSpaceSelections,
    additionalInformationSelections: AdditionalInformationSelections,
    contactDetailsSelections: ContactDetailsSelections,
): RequestBreathingSpace => {
    return {
        ...buildRequestFragmentCommon(firstName, lastName, emailAddress),
        ...buildRequestFragmentOptionsAvailable(optionsAvailableSelections),
        ...buildRequestFragmentBreathingSpace(breathingSpaceSelections),
        ...buildRequestFragmentAdditionalInformation(additionalInformationSelections),
        ...buildRequestFragmentContactDetails(contactDetailsSelections),
    };
};

export const buildRequestOtherOptions = (
    firstName: string | undefined,
    lastName: string | undefined,
    emailAddress: string,
    optionsAvailableSelections: OptionsAvailableSelections,
    otherOptionsSelections: OtherOptionsSelections,
    additionalInformationSelections: AdditionalInformationSelections,
    contactDetailsSelections: ContactDetailsSelections,
): RequestBreathingSpace => {
    return {
        ...buildRequestFragmentCommon(firstName, lastName, emailAddress),
        ...buildRequestFragmentOptionsAvailable(optionsAvailableSelections),
        ...buildRequestFragmentOtherOptions(otherOptionsSelections),
        ...buildRequestFragmentAdditionalInformation(additionalInformationSelections),
        ...buildRequestFragmentContactDetails(contactDetailsSelections),
    };
};
